.main-footer{
  color: var(--color-grey);
  font-size: 0.8125rem;

  a{
    color: inherit;
  }
}

.main-footer__content{
  border-top: 1px solid var(--color-black);
  padding-bottom: 25px;
  padding-top: 25px;

  @media (min-width: 768px) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}