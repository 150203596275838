.main-content{
  padding-top: 150px;
}

.main-title{
  border-bottom: 3px solid var(--color-black);
  font-family: var(--font-secondary);
  font-size: 2.5rem;
  font-weight: normal;
  line-height: 1;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 4.5rem;
  }
}

.sub-title{
  border-bottom: 3px solid var(--color-black);
  font-family: var(--font-secondary);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  padding-bottom: 3px;
  text-transform: uppercase;
}

.page{
  margin-bottom: 117px;
}

.page-header{
  margin-bottom: 39px;
}

.page-content{
  padding-left: 5%;
  padding-right: 5%;

  p:not(:last-child){
    margin-bottom: 21px;
  }

  h2:not(.sub-title){
    font-family: var(--font-secondary);
    font-size: 1.3rem;
    line-height: 1.2;
    margin-bottom: 12px;
    padding-top: 6px;
    text-transform: uppercase;

    @media (min-width: 768px) {
      font-size: 1.5rem;
    }
  }

  h3{
    font-family: var(--font-secondary);
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.1;
    margin-bottom: 6px;
    text-transform: uppercase;
  }

  @media (min-width: 768px) {
    padding-left: 10%;
    padding-right: 10%;
  }
}

.page__content{
  margin-bottom: 72px;
}
