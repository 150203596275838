:root {
  --color-black: #000;
  --color-white: #fff;
  --color-grey: #5B5B5B;
  --font-primary: "BLA", Arial, Helvetica, sans-serif;
  --font-secondary: "BL", Arial, Helvetica, sans-serif;
  --text-color: #000;
}

/* html5doctor.com Reset v1.6.1 - http://cssreset.com */
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

html {
  font-size: 100%;
}

body {
  color: var(--text-color);
  font-family: var(--font-primary);
  line-height: 1.5;
}

a {
  color: inherit;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  transition: text-decoration-thickness 0.2s;
}
a:hover, a:focus {
  text-decoration-thickness: 3px;
}

.box-content {
  padding-left: 2%;
  padding-right: 2%;
}
@media (min-width: 768px) {
  .box-content {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.is-responsive-image {
  height: auto;
  width: 100%;
}

.is-hidden-link {
  text-decoration: none;
}

.main-header {
  align-items: center;
  background-color: var(--color-white);
  box-sizing: border-box;
  color: var(--color-black);
  display: flex;
  height: 80px;
  left: 0;
  position: fixed;
  top: 0;
  transition: background-color 0.5s, color 0.5s;
  width: 100%;
  z-index: 101;
}
@media (min-width: 767px) {
  .main-header {
    justify-content: center;
  }
}

.main-header--inverted {
  background-color: var(--color-black);
  color: var(--color-white);
}

.main-navigation-trigger {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 0;
}

.main-navigation-trigger__icon {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  transition: transform 400ms;
  user-select: none;
}
.main-navigation-trigger__icon .top {
  stroke-dasharray: 40 82;
}
.main-navigation-trigger__icon .middle {
  stroke-dasharray: 40 111;
}
.main-navigation-trigger__icon .bottom {
  stroke-dasharray: 40 161;
}
.main-navigation-trigger__icon.main-navigation-trigger__icon--active .top {
  stroke-dasharray: 17 82;
  stroke-dashoffset: -62px;
}
.main-navigation-trigger__icon.main-navigation-trigger__icon--active .middle {
  stroke-dashoffset: 23px;
}
.main-navigation-trigger__icon.main-navigation-trigger__icon--active .bottom {
  stroke-dashoffset: -83px;
}

.main-navigation-trigger__icon--active {
  transform: rotate(45deg);
}
.main-navigation-trigger__icon--active .line {
  stroke: var(--color-white);
}

.line {
  fill: none;
  stroke: var(--color-black);
  stroke-width: 4;
  stroke-linecap: round;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms, stroke 0.5s;
}

.main-logo {
  color: inherit;
  display: flex;
  font-family: var(--font-secondary);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  position: relative;
  text-transform: uppercase;
  text-decoration: none;
}
@media (max-width: 767px) {
  .main-logo {
    align-items: center;
    gap: 9px;
  }
}
@media (min-width: 768px) {
  .main-logo {
    display: block;
    font-size: 1.3125rem;
  }
}

@media (max-width: 767px) {
  .main-logo__payoff {
    display: block;
  }
}
@media (min-width: 768px) {
  .main-logo__payoff {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (min-width: 768px) {
  .main-logo__payoff--1 {
    right: 120%;
    width: 250px;
    text-align: right;
  }
}

@media (min-width: 768px) {
  .main-logo__payoff--2 {
    left: 120%;
    width: 195px;
  }
}

.main-navigation {
  align-items: center;
  background-color: var(--color-black);
  color: var(--color-white);
  display: flex;
  height: calc(100vh - 80px);
  justify-content: center;
  left: 0;
  position: fixed;
  top: -100%;
  width: 100%;
  transition: top 0.5s;
  z-index: 100;
}

.main-navigation--visible {
  top: 80px;
}

.main-navigation__content {
  box-sizing: border-box;
  padding-left: 5%;
  padding-right: 5%;
  width: 100%;
}

.front-section {
  margin-bottom: 45px;
}
@media (min-width: 768px) {
  .front-section {
    margin-bottom: 90px;
  }
}

.front-section--menu {
  margin-bottom: 42px;
}
.front-section--menu .main-title {
  border-color: var(--color-white);
  font-size: 2.25rem;
}
.front-section--menu .front-section__items {
  gap: 9px;
}
.front-section--menu .front-section__item {
  font-size: 1rem;
}
.front-section--menu .front-section__link {
  text-decoration: underline;
  text-decoration-color: var(--color-white);
}

.front-section__items {
  margin-top: 36px;
  padding-left: 30px;
}
@media (min-width: 768px) {
  .front-section__items {
    display: flex;
    gap: 18px;
    padding-left: 60px;
  }
}

.front-section__item {
  font-size: 1.3rem;
  font-weight: 500;
  list-style: none;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .front-section__item {
    font-size: 1.5rem;
  }
}

.front-section__item--label {
  font-size: 1.3125rem;
  font-weight: normal;
  text-transform: none;
}

.front-section__link {
  color: inherit;
  transition: text-decoration-thickness 0.2s;
}
.front-section__link:hover, .front-section__link:focus, .front-section__link.front-section__link--active {
  text-decoration-thickness: 3px;
}

.main-content {
  padding-top: 150px;
}

.main-title {
  border-bottom: 3px solid var(--color-black);
  font-family: var(--font-secondary);
  font-size: 2.5rem;
  font-weight: normal;
  line-height: 1;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .main-title {
    font-size: 4.5rem;
  }
}

.sub-title {
  border-bottom: 3px solid var(--color-black);
  font-family: var(--font-secondary);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  padding-bottom: 3px;
  text-transform: uppercase;
}

.page {
  margin-bottom: 117px;
}

.page-header {
  margin-bottom: 39px;
}

.page-content {
  padding-left: 5%;
  padding-right: 5%;
}
.page-content p:not(:last-child) {
  margin-bottom: 21px;
}
.page-content h2:not(.sub-title) {
  font-family: var(--font-secondary);
  font-size: 1.3rem;
  line-height: 1.2;
  margin-bottom: 12px;
  padding-top: 6px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .page-content h2:not(.sub-title) {
    font-size: 1.5rem;
  }
}
.page-content h3 {
  font-family: var(--font-secondary);
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 6px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .page-content {
    padding-left: 10%;
    padding-right: 10%;
  }
}

.page__content {
  margin-bottom: 72px;
}

.main-footer {
  color: var(--color-grey);
  font-size: 0.8125rem;
}
.main-footer a {
  color: inherit;
}

.main-footer__content {
  border-top: 1px solid var(--color-black);
  padding-bottom: 25px;
  padding-top: 25px;
}
@media (min-width: 768px) {
  .main-footer__content {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}