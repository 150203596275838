.main-logo{
  color: inherit;
  display: flex;
  font-family: var(--font-secondary);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  position: relative;
  text-transform: uppercase;
  text-decoration: none;

  @media (max-width: 767px) {
    align-items: center;
    gap: 9px;
  }

  @media (min-width: 768px) {
    display: block;
    font-size: 1.3125rem;
  }
}

.main-logo__payoff{
  @media (max-width: 767px) {
    display: block;
  }

  @media (min-width: 768px) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.main-logo__payoff--1{
  @media (min-width: 768px) {
    right: 120%;
    width: 250px;
    text-align: right;
  }
}

.main-logo__payoff--2{
  @media (min-width: 768px) {
    left: 120%;
    width: 195px;
  }
}