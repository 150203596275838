.main-header{
  align-items: center;
  background-color: var(--color-white);
  box-sizing: border-box;
  color: var(--color-black);
  display: flex;
  height: 80px;
  left: 0;
  position: fixed;
  top: 0;
  transition: background-color .5s, color .5s;
  width: 100%;
  z-index: 101;

  @media (min-width: 767px) {
    justify-content: center;
  }
}

.main-header--inverted{
  background-color: var(--color-black);
  color: var(--color-white);
}

.main-navigation-trigger{
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 0;
}

.main-navigation-trigger__icon{
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  transition: transform 400ms;
  user-select: none;

  .top {
    stroke-dasharray: 40 82;
  }

  .middle {
    stroke-dasharray: 40 111;
  }

  .bottom {
    stroke-dasharray: 40 161;
  }

  &.main-navigation-trigger__icon--active{
    .top {
      stroke-dasharray: 17 82;
      stroke-dashoffset: -62px;
    }

    .middle {
      stroke-dashoffset: 23px;
    }

    .bottom {
      stroke-dashoffset: -83px;
    }
  }
}

.main-navigation-trigger__icon--active{
  transform: rotate(45deg);

  .line{
    stroke: var(--color-white);
  }
}

.line {
  fill: none;
  stroke: var(--color-black);
  stroke-width: 4;
  stroke-linecap: round;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms, stroke .5s;
}
