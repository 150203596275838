html{
  font-size: 100%;
}

body{
  color: var(--text-color);
  font-family: var(--font-primary);
  line-height: 1.5;
}

a{
  color: inherit;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  transition: text-decoration-thickness .2s;

  &:hover,
  &:focus{
    text-decoration-thickness: 3px;
  }
}

.box-content{
  padding-left: 2%;
  padding-right: 2%;

  @media (min-width: 768px) {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.is-responsive-image{
  height: auto;
  width: 100%;
}

.is-hidden-link{
  text-decoration: none;
}