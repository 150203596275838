.main-navigation{
  align-items: center;
  background-color: var(--color-black);
  color: var(--color-white);
  display: flex;
  height: calc(100vh - 80px);
  justify-content: center;
  left: 0;
  position: fixed;
  top: -100%;
  width: 100%;
  transition: top .5s;
  z-index: 100;
}

.main-navigation--visible{
  top: 80px;
}

.main-navigation__content{
  box-sizing: border-box;
  padding-left: 5%;
  padding-right: 5%;
  width: 100%;
}