.front-section{
  margin-bottom: 45px;

  @media (min-width: 768px) {
    margin-bottom: 90px;
  }
}

.front-section--menu{
  margin-bottom: 42px;

  .main-title{
    border-color: var(--color-white);
    font-size: 2.25rem;
  }

  .front-section__items{
    gap: 9px;
  }

  .front-section__item{
    font-size: 1rem;
  }

  .front-section__link{
    text-decoration: underline;
    text-decoration-color: var(--color-white);
  }
}

.front-section__items{
  margin-top: 36px;
  padding-left: 30px;

  @media (min-width: 768px) {
    display: flex;
    gap: 18px;
    padding-left: 60px;
  }
}

.front-section__item{
  font-size: 1.3rem;
  font-weight: 500;
  list-style: none;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
}

.front-section__item--label{
  font-size: 1.3125rem;
  font-weight: normal;
  text-transform: none;
}

.front-section__link{
  color: inherit;
  transition: text-decoration-thickness .2s;

  &:hover,
  &:focus,
  &.front-section__link--active{
    text-decoration-thickness: 3px;
  }
}
